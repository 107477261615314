@use '../../../globals/variables' as *;

.button {
  width: 100%;
  height: 38px;
  border-radius: 10px;
  background-color: #FF0606BB;
  padding: 0;
  font-family: $main-font-family, sans-serif;
  font-size: 17px;
  font-weight: 500;
  line-height: 21.67px;
  text-align: center;
  color: $font-color-light;
  border: 0;
  cursor: pointer;
}
