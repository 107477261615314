@use '../../globals/variables' as *;

.home-page {
  background-color: $background-color;
  min-height: 100dvh;
  font-family: $main-font-family, sans-serif;
  overflow: hidden;
}

.main-section,
.idea-section,
.nlp-section,
.public-section,
.examples-section,
.trial-section {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home-background-img {
  display: block;
  user-select: none;
  width: 100%;

  img {
    width: 100%;
  }
}

.device-img {
  display: block;
  user-select: none;
  width: 100%;
  margin-top: calc(2rem + 3vw);

  img {
    width: 100%;
  }
}

.public-img {
  display: block;
  user-select: none;
  width: 80%;

  img {
    width: 100%;
  }
}

.examples-img {
  display: block;
  user-select: none;
  width: 80%;
  margin-top: calc(2rem + 3vw);

  img {
    width: 100%;
  }
}

.home-header {
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 25px 4vw;
  column-gap: 4vw;
}

.logotype-header {
  position: relative;
  bottom: 8px;
  width: 231px;
  align-self: center;
  flex: 0 1 auto;

  &.logotype__light g path {
    fill: $logotype-color;
  }
}

.header-navigation {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 21px;
  justify-content: space-evenly;
  column-gap: 16px;
  flex: 1 0 16px;
  text-wrap: nowrap;
  color: $font-color-light;

  a {
    color: unset;
    text-decoration: none;
  }
}

.saved-button {
  display: inline-flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  text-decoration: none;

  width: 183px;
  height: 45px;
  color: rgba(0,0,0,.6);
  font-size: 17px;
  font-weight: 600;
  background-color: transparentize($logotype-color, .2);
  border: 1px solid #BEB6A6;
  border-radius: 11px;

  path {
    opacity: .6;
  }
}

.gradient-title {
  position: absolute;
  top: calc(8rem + 6vw);
  width: 100%;
  background-size: 100% 100%;
  background-position: 0 0;
  background-image: conic-gradient(from 91deg at 45% 47%, #6FD4FF 25%, #6FFF8F 61%, #FFDF6F 88%);
  background-clip: text;
  color: transparent;
  margin: 0;
  text-wrap: nowrap;

  font-family: $title-font-family, sans-serif;
  font-size: calc(1rem + 3vw);
  text-align: center;
}

.marquee-wrapper {
  position: absolute;
  bottom: -80px;
  background-color: $background-color;
  width: 110%;
  height: 200px;
  transform: rotate(-2deg);
  margin-top: -80px;
}

.marquee {
  display: flex;
  align-items: center;
  background-size: 100% 100%;
  background-position: 0 0;
  background-image: linear-gradient(90deg, #50A4C8 8%, #5FDF7B 100%);
  width: 100%;
  height: 50px;
  position: relative;
  bottom: 5px;

  text-wrap: nowrap;
  font-family: $main-font-family, sans-serif;
  font-size: 21px;
  font-weight: 600;
  color: #FFF1D5;

  .marquee--in {
    display: flex;
    width: fit-content;
    animation: marquee 20s linear infinite;
    -webkit-transform-style: preserve-3d; /* Фикс возможного глюка с морганием в Сафари */
  }

  .marquee--item {
    white-space: nowrap;
    -webkit-backface-visibility: hidden; /* Фикс возможного глюка с морганием в Сафари */
  }

  .marquee--item + .marquee--item:after {
    padding: .5rem;
    content: '↓';
  }
}

.idea-title {
  margin: calc(4rem + 3vw) 0 0;
  font-family: $title-font-family, sans-serif;
  font-size: calc(1rem + 3vw);
  color: $green-dark;
}

.nlp-logotype {
  position: relative;
  top: 0;
}

.circles {
  svg {
    max-width: 654px;
    max-height: 654px;
    width: 80vw;
    height: 80vw;
  }

  circle {
    fill: transparent;
    stroke-width: 1px;
    stroke: black;
    opacity: .2;
    cx: 50%;
    cy: 50%;
  }
}

.lesnikai-title {
  position: absolute;
  top: calc(50% - calc(2rem + 4vw)/2);
  width: 100%;
  background-size: 120% 120%;
  background-position: 0 0;
  background-image: conic-gradient(from 155deg at 43% 25%, #00B3FF 0%, #FFC700 23%, #00FF38 51%, #00B3FF 88%);
  background-clip: text;
  color: transparent;
  margin: 0;
  text-wrap: nowrap;
  user-select: none;

  font-family: $title-font-family, sans-serif;
  font-size: calc(2rem + 4vw);
  text-align: center;
  z-index: 1;
}

.gradient-background {
  top: calc(50% - 4rem - 4vw);
  font-size: calc(4rem + 8vw);
  background-size: 100% 100%;
  background-position: 0 0;
  background-image: conic-gradient(from 155deg at 50%, #00B3FF 0%, #FFC700 23%, #00FF38 51%, #00B3FF 88%);
  filter: blur(50px);
  opacity: .6;
}

.gradient-background_trial {
  z-index: 0;
  top: calc(50% - 4rem - 3vw);
  opacity: .5;
}

.trial-title {
  font-family: $main-font-family, sans-serif;
  font-size: max(17px, calc((1rem + 2.5vw)/2));
  color: $font-color-dark;
  text-align: center;
  font-weight: 400;
  line-height: 1.25;
  margin-top: 69px;
}

.description {
  font-family: $main-font-family, sans-serif;
  font-size: max(16px, calc((1rem + 2vw)/2));
  color: $font-color-dark;
  text-align: center;
  font-weight: 400;
  line-height: 1.25;
  z-index: 1;
  width: 35vw;
  white-space: pre-wrap;

  &.idea-description {
    margin-top: 2rem;
  }

  &.nlp-description {
    margin: 5rem 0 3rem;
  }

  &.public-description {
    margin: 8rem 0 6rem;
  }

  &.examples-description {
    margin: 5rem 0 1rem;
  }

  &.examples-description + .examples-description {
    margin: 0;
  }

  &.trial-description {
    width: calc(335px + 15vw);
    font-size: max(16px, calc((1rem + 1vw) / 2));
  }
}

.try-button {
  font-family: $main-font-family, sans-serif;
  font-size: calc((1rem + 3vw)/2);
  font-weight: 600;
  color: $font-color-light;
  border: none;
  border-radius: 24px;
  background-color: $green-dark;
  width: 335px;
  height: 90px;
  z-index: 1;
  margin: 4rem 0 3rem;
}

.try-button:hover {
  transition: background-color .1ms ease-out, color .1ms ease-out;
  background-color: $hover-background-color;
  color: $hover-text-color;
}

.home-footer {
  display: flex;
  justify-content: space-between;
  font-family: $main-font-family, sans-serif;
  color: $font-color-dark;
  padding: 30px 40px;

  .footer-left {
    display: flex;
    flex-direction: column;

    .rights {
      font-size: 17px;
    }

    .icon-usage {
      font-size: 13px;
      opacity: .5;

      position: relative;
      left: 20px;
    }
  }
}

.search-button {
  position: absolute;
  height: 54.09px;
  width: 557.53px;
  top: calc(8rem + 20vw);
  border-radius: 11.65px;
  background-color: transparentize($logotype-color, .6);
  border: 1px solid $border-color;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  font-family: $main-font-family, sans-serif;
  font-size: 19.14px;
  font-weight: 400;
  line-height: 24.4px;
  text-decoration: none;
  cursor: pointer;
}

.logotype-footer {
  box-sizing: content-box;
  width: 231px;
  padding-bottom: 28px;
}

@keyframes marquee {
  0% { transform: translateX(0); }
  100% { transform: translateX(-50%); }
}

@media screen and (max-width: $l-screen-size) {
  .home-background-img {
    width: 125%;
  }

  .home-header {
    justify-content: space-between;
  }

  .logotype-header {
    width: 200px;
    align-self: end;
  }

  .logotype-footer {
    width: 200px;
  }

  .header-navigation {
    display: none;
  }

  .saved-button {
    width: 48px;
    height: 48px;

    span {
      display: none;
    }
  }

  .description {
    width: 50vw;
    &.idea-description {
      margin-top: 2rem;
    }

    &.nlp-description {
      bottom: 2rem;
    }

    &.trial-description {
      width: calc(200px + 20vw);
      font-size: max(14px, calc((1rem + 1vw) / 2));
    }
  }

  .try-button {
    width: 200px;
    height: 53px;
    border-radius: 14px;
    margin: 2.5rem 0 2rem;
  }

  .search-button {
    top: calc(8rem + 22vw);
  }
}

@media screen and (max-width: $m-screen-size) {
  .home-background-img {
    width: 150%;
  }

  .marquee-wrapper {
    margin-top: -2rem;
    bottom: -80px;
    height: 80px;
  }

  .marquee {
    font-size: 1rem;
    height: 38px;
    bottom: 30px;
  }

  .search-button {
    width: 557.53px;
    top: calc(8rem + 24vw);
  }

  .description {
    width: 60vw;
  }
}

@media screen and (max-width: $s-screen-size) {
  .logotype-header {
    width: 175px;
    align-self: end;
  }

  .logotype-footer {
    width: 150px;
  }

  .home-background-img {
    width: 200%;
  }

  .description {
    width: 75vw;
    &.idea-description {
      margin-top: 2rem;
    }

    &.nlp-description {
      bottom: 0;
    }

    &.examples-description {
      margin: 0 0 1rem;
    }

    &.public-description {
      margin-bottom: 0;
    }

    &.trial-description {
      width: calc(200px + 20vw);
    }
  }

  .device-img {
    margin-top: -1rem;
  }

  .search-button {
    width: 364px;
    top: calc(8rem + 30vw);
  }
}

@media screen and (max-width: $xs-screen-size) {
  .home-background-img {
    width: 200%;
  }

  .gradient-background_trial {
    top: calc(50% - 3rem);
  }

  .gradient-title {
    font-size: 26px;
  }

  .home-footer {
    padding: 20px 30px;
    .rights {
      font-size: calc((1rem + 1.5vw) / 2);
    }

    .icon-usage {
      font-size: calc((1rem + 1vw) / 2);
      left: 13px;
    }
  }

  .search-button {
    width: 340px;
    top: calc(8rem + 34vw);
  }

  .description {
    width: 90vw;
    &.trial-description {
      font-size: max(12px, calc((1rem + 1vw) / 2));
    }
  }
}
