@use '../../globals/variables' as *;
@import 'react-datepicker/dist/react-datepicker';

.react-datepicker-wrapper{
  width: 100%;
}

.calendar {
  border: 1px solid $border-color;
  background: $background-color;
  width: 238px;
}

.react-datepicker__month-container{
  width: 100%;
}

.react-datepicker__header--custom {
  background: transparentize($background-color, .8);
  width: 236px;
  border: 0;
}

.react-datepicker__month {
  margin-top: 0;
}

.react-datepicker__day {
  font-family: $main-font-family, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 19.12px;
  text-align: center;
  color: transparentize(black, .4);

  &:hover{
    background-color: transparentize($green-dark, .3);
    border: 0;
  }
}

.react-datepicker__day--outside-month{
  color: #65625D;
  opacity: 0.6;
}

.react-datepicker__day--in-range {
  background-color: $green-dark;
  margin-right: 0;
  margin-left: 0;
  width: 2.031rem;
  border-radius: 0;
  border: 0;
  color: #FDF6E8;

  &:hover {
    border-radius: 0;
  }
}

.react-datepicker__day--range-start {
  width: 1.866rem;
  border-bottom-left-radius: 0.3rem;
  border-top-left-radius: 0.3rem;
  margin-left: 0.166rem;

  &:hover {
    border-bottom-left-radius: 0.3rem;
    border-top-left-radius: 0.3rem;
  }
}

.react-datepicker__day--range-end {
  width: 1.86rem;
  border-bottom-right-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  margin-right: 0.166rem;

  &:hover {
    border-bottom-right-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
  }
}

.week-day {
  font-family: $main-font-family, sans-serif;
  font-size: 11px;
  font-weight: 400;
  line-height: 14.02px;
  text-align: center;
  color: transparentize(black, .6);
}

.calendar-input {
  width: 100%;
  height: 38px;
  border-radius: 10px;
  border: solid 1px $border-color;
  background-color: $background-color;

  .text {
    display: flex;
    padding-left: 35px;
    flex-direction: row;
    justify-content: space-between;
    opacity: 0.6;
    color: black;
    font-family: $main-font-family, sans-serif;
    font-size: 17px;
    font-weight: 500;
    line-height: 21.67px;
    text-align: center;

    .arrow-rotated {
      rotate: 180deg;
    }
  }
}

.calendarIconContainer {
  display: flex;
  height: 100%;
  width: 35px;
  padding: 0 !important;

  svg {
    margin: auto;
  }
}
