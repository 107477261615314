@use '../../../globals/variables' as *;

.button {
  width: 100%;
  height: 38px;
  border-radius: 10px;
  background-color: $logotype-color;
  padding: 0;
  font-family: $main-font-family, sans-serif;
  font-size: 17px;
  font-weight: 500;
  line-height: 21.67px;
  text-align: center;
  color: transparentize(black, .6);
  border: 1px solid $border-color;
  cursor: pointer;
  padding: 4px;
  white-space: nowrap;
}
