body {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background-color: #f5f5f5;
  color: #333;
  margin: 0;
  padding: 0;
  line-height: 1.5;
}

.policy-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 40px 20px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

h1 {
  color: #004080;
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 20px;
}

h2 {
  color: #004080;
  font-size: 24px;
  font-weight: bold;
  margin-top: 40px;
  margin-bottom: 10px;
}

p {
  margin-bottom: 15px;
}

ul {
  margin-top: 0;
  margin-bottom: 15px;
  padding-left: 20px;
  list-style-type: none;
}

h3 {
  margin-top: 0;
  margin-bottom: 15px;
  padding-left: 20px;
}

li {
  margin-bottom: 5px;
  animation: slideInLeft 1s ease-in-out;
  position: relative;
}

@keyframes slideInLeft {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.list-container {
  margin-bottom: 20px;
}

@media (max-width: 600px) {
  .container {
    max-width: 100%;
    padding: 20px;
  }

  h1 {
    font-size: 24px;
  }

  h2 {
    font-size: 18px;
  }
}
