@use '../../globals/variables' as *;

.search-filters-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-width: 557.53px;
  width: 100%;

  .input-wrapper {
    height: 54.09px;
    border-radius: 11.65px;
    background: $logotype-color;
    border: 1px solid $border-color;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;

    &:focus-within, &:focus-visible {
      outline: 1px auto #8C877C;
    }

    input {
      width: 100%;
      height: 34px;
      background: $logotype-color;
      border: 0;

      font-family: $main-font-family, sans-serif;
      font-size: large;
      font-weight: 400;
      line-height: 24.4px;
      text-align: left;
      color: rgba(black, .6);
    }

    input:focus-visible{
      outline: 0;
    }
  }
}

@media screen and (max-width: $s-screen-size) {
  .search-filters-container .input-wrapper input {
    font-size: medium;
  }
}
