@use '../../globals/variables' as *;

.button-filters-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;

  button {
    border: 0;
  }

  .one-section {
    border: 1px solid $border-color;
    display: flex;
    flex-direction: row;
    background: $background-color;
    border-radius: 10px;
  }
}
