@use '../../globals/variables' as *;

.filter-modal-wrapper {
  height: 45px;
  position: relative;

  .filter-modal-container {
    transition: visibility 0s, opacity .1s ease-out;
    visibility: visible;
    position: absolute;
    top: 0;
    z-index: 2;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
    max-width: 495px;
    width: 100%;
    border-radius: 14px;
    border: 1px solid transparentize($border-color, .6);
    backdrop-filter: blur(25px) opacity(1);
    background: transparentize($logotype-color, .6);
    padding: 10px;

    .calendar-section {
      display: flex;
      flex-direction: row;
      gap: 12px;
    }

    .buttons {
      button {
        width: 141px;
      }

      text-align: right;
      display: flex;
      flex-direction: row;
      justify-content: right;
      gap: 12px;
    }

    @media screen and (max-width: $xs-screen-size) {
      .calendar-section {
        flex-direction: column;
      }
    }
  }

  .filter-modal-container.closed {
    height: 0;
    padding: 0;
    opacity: 0;
    visibility: hidden;
  }

  .close-area {
    position: fixed;
    opacity: 0;
    left: 0;
    top: 0;
    width: 100rem;
    height: 100rem;
    z-index: 1;
  }

  .close-area.closed {
    display: none;
  }

  .visible-button {
    display: flex;
    flex-direction: row;
    gap: 10px;

    .filter-open-button-container {
      font-size: medium;
      width: 216px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      color: transparentize(black, .4);
    }

    .text {
      font-family: $main-font-family, sans-serif;
      font-size: 12.48px;
      font-weight: 500;
      line-height: 15.91px;
      text-align: left;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 230px;
      color: transparentize(black, .6);
    }
  }

  .closed-button {
    visibility: hidden;
  }

  @media screen and (max-width: $s-screen-size) {
    .visible-button {
      flex-direction: column;

      .filter-open-button-container{
        width: 100%;
        font-size: smaller;
      }

      .text{
        width: 100%;
      }
    }
  }
}
