@use '../../globals/variables' as *;

.hike-modal-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  height: 100dvh;
  width: 100dvw;
  z-index: 2;
  padding: 0 14%;

  .back-button-modal {
    align-self: flex-start;
    display: none;
    gap: 8px;
    align-items: center;
    justify-content: flex-start;
    text-decoration: none;

    height: 43px;
    color: $green-dark;
    font-size: 18px;
    font-weight: 600;
    border: 0;
    background-color: transparent;
    margin-top: -43px;
    padding: 0;
    z-index: 1;

    path {
      stroke: $green-dark;
    }

    &__label {
      opacity: .6;
    }
  }

  .back-button-modal:hover {
    transition: color .1ms ease-out;
    color: $hover-background-color;

    path {
      stroke: $hover-background-color;
    }
  }

  .backdrop {
    position: fixed;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(40px);
  }

  .hike-modal {
    z-index: 1;
    background-color: $logotype-color;
    border: 1px solid $border-color;
    width: 100%;
    height: 82%;
    display: flex;
    flex-direction: column;
    gap: 13px;
    border-radius: 16px;
    overflow: auto;
    padding: 15px 16px 22px 16px;

    .modal-img-wrapper {
      flex: 0 1 auto;
      border-radius: 11px;
      overflow: hidden;
      height: 216px;
      min-height: 213px;
      box-sizing: content-box;
    }

    .modal-img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center;
    }

    .modal-content {
      display: flex;
      flex-direction: column;
      gap: 13px;
      flex: 1 0 auto;
      padding: 0 10px;
    }

    .hike-description {
      font-family: $main-font-family, sans-serif;
      font-size: 14px;
      color: $font-color-dark;
      font-weight: 500;
      width: 80%;
      white-space: pre-wrap;
    }

    .hike-description a {
      &:link, &:visited {
        color: #596F2B;
      }

      &:hover, &:active {
        color: #FFDF6F;
      }
    }

    .modal-header-wrapper {
      display: flex;
      column-gap: 16px;
      justify-content: space-between;
      align-items: baseline;
    }

    .hike-modal-title {
      display: block;
      font-family: $title-font-family, sans-serif;
      color: $green-dark;
      font-size: max(3vw, 28px);
      margin: 0;
      max-width: 100%;
      overflow: hidden;
      word-break: break-word;
      line-break: auto;

      line-height: 1.1em;
      max-height: 3.3em;
      /* Fallback for non-webkit, line-height * 3 */
      /* кроссбраузерное решение обрезания строк, не добавляет "..." */


      display: -webkit-box;
      -webkit-line-clamp: 3;
      line-clamp: 3;
      -webkit-box-orient: vertical;
      /* https://caniuse.com/css-line-clamp */
    }

    .hike-date {
      display: flex;
      flex-direction: column;

      .hike-time {
        font-family: $main-font-family, sans-serif;
        font-weight: 900;
        color: $green-dark;
        font-size: max(3vw, 23px);
        line-height: 1;
      }

      .hike-duration {
        font-family: $main-font-family, sans-serif;
        font-size: max(1.5vw, 14px);
        color: $green-dark;
        margin-top: -4px;
        align-self: flex-end;
      }
    }

    .buttons-container {
      display: flex;
      gap: 10px;
      width: max-content;
      align-self: flex-end;

      button {
        width: 149px;
        height: 40px;
        border-radius: 10px;
        border: none;
        font-size: 14px;
        font-weight: 700;
        color: $font-color-light;
      }

      a {
        width: 149px;
        height: 40px;
        border-radius: 10px;
        border: none;
        font-size: 14px;
        font-weight: 700;
        color: $font-color-light;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        cursor: pointer;
      }

      .hike-post-button {
        font-weight: bold;
        background-color: $green-dark;

        &:hover {
          transition: background-color .1ms ease-out, color .1ms ease-out;
          background-color: $hover-background-color;
          color: $hover-text-color;
        }
      }

      .hike-back-button {
        background-color: $light-brown;
      }
    }
  }
}

@media screen and (max-width: $m-screen-size) {
  .hike-modal-wrapper {
    padding: 0 5%;

    .backdrop {
      background-color: rgba(255,241,213,.8);
    }

    .back-button-modal {
      display: inline-flex;
    }
  }

  .hike-modal-wrapper .hike-modal {
    padding: 12px 12px 22px;
    height: 85%;

    .modal-header-wrapper {
      flex-direction: column;
    }

    .hike-date .hike-duration {
      align-self: flex-start;
    }

    .hike-description {
      width: 100%;
    }

    .modal-content {
      padding: 0 8px;
    }

    div.buttons-container {
      align-self: flex-start;

      .hike-back-button {
        display: none;
      }
    }
  }
}

body:has(.hike-modal_open) {
  position: fixed;
  height: 100vh;
  overflow: hidden;
}
