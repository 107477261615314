@use '../../globals/variables' as *;

.results-container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 9px 13px;
  grid-auto-flow: row;
  grid-auto-rows: 291px;
}

@media screen and (max-width: $l-screen-size) {
  .results-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: $m-screen-size) {
  .results-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

