// Colors
$green-dark: #596F2B;
$light-brown: #8C744D;

// Theme
$background-color: #FFF6E5;
$logotype-color: #E9E1CF;
$main-font-family: 'Onest';
$title-font-family: 'Unbounded';
$font-color-light: #FFF6E5;
$font-color-dark: #66625C;
$hover-background-color: #FFDF6F;
$hover-text-color: #131918;
$border-color: #C9C1B0;

// Components
$tag-default: $green-dark;
$tag-free: #7FC752;
$tag-paid: #E3B338;
$tag-paid: #E3B338;
$tag-type: #756B2D;

//sizes
$l-screen-size: 992px;
$m-screen-size: 768px;
$s-screen-size: 576px;
$xs-screen-size: 393px;