@use '../../globals/variables' as *;

.container {
  display: flex;
  justify-content: space-between;
  width: 238px;
  margin-bottom: 10px;

  .date-text {
    font-family: $main-font-family, sans-serif;
    font-size: 15px;
    font-weight: 500;
    line-height: 19.12px;
    text-align: left;
    color: $green-dark;
  }

  .arrows {
    display: flex;
    justify-content: space-between;

    button {
      background: transparentize($background-color, .8);
      border: 0;
    }
  }
}
