@use "globals/globals-dir";

$fonts-path: '../public/fonts';

@font-face {
    font-family: "Peace Sans";
    src: url($fonts-path + '/PeaceSans.ttf') format("truetype");
}

@font-face {
    font-family: "Unbounded";
    src: url($fonts-path + '/Unbounded-Black.ttf') format("truetype");
}
