@use '../../globals/variables' as *;

.hikes-page {
  background-color: $background-color;
  min-height: 100dvh;
  font-family: $main-font-family, sans-serif;
  overflow: hidden;
}

.hikes-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 25px 4vw;
  column-gap: 4vw;
}

.search-section, .results-section, .end-section {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.search-section {
  padding: 0 4vw;
}

.results-section {
  margin-top: 50px;
  width: 100%;
  padding: 0 10.5vw;
}

.end-section {
  margin: 150px 0;
}

.saved-button {
  display: inline-flex;
  opacity: 0;
  gap: 8px;
  align-items: center;
  justify-content: center;
  text-decoration: none;

  width: 183px;
  height: 45px;
  color: rgba(0,0,0,.6);
  font-size: 17px;
  font-weight: 600;
  background-color: transparentize($logotype-color, .2);
  border: 1px solid #BEB6A6;
  border-radius: 11px;

  path {
    opacity: .6;
  }
}

.back-button {
  align-self: flex-start;
  display: inline-flex;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;
  position: absolute;

  height: 45px;
  color: $green-dark;
  font-size: 20px;
  font-weight: 600;

  path {
    stroke: $green-dark;
  }

  &__label {
    opacity: .6;
  }
}

.back-button:hover {
  transition: color .1ms ease-out;
  color: $hover-background-color;

  path {
    stroke: $hover-background-color;
  }
}

.results-title {
  font-family: $title-font-family, sans-serif;
  font-size: calc(1rem + 2vw);
  color: $green-dark;
  align-self: flex-start;
  margin-bottom: 34px;
}

.results-container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 9px 13px;
  grid-auto-flow: row;
  grid-auto-rows: 291px;
}

.hike-card {
  display: flex;
  flex-direction: column;
  gap: 13px;
  background-color: $logotype-color;
  border: 1px solid $border-color;
  border-radius: 16px;
  overflow: hidden;
  padding: 8px;

  .card-img-wrapper {
    flex: 1 1 auto;
    border-radius: 11px;
    overflow: hidden;
    height: 101px;
    box-sizing: content-box;
  }

  .card-img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }

  .card-content {
    display: flex;
    flex-direction: column;
    gap: 13px;
    flex: 1 0 auto;
    padding: 0 4px;
  }

  .card-title {
    font-family: $title-font-family, sans-serif;
    color: $green-dark;
    font-size: max(1.5vw, 16px);
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .tag-container {
    display: flex;
    flex-wrap: wrap;
    gap: 3px 5px;
  }

  .tag {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    height: fit-content;
    padding: 2px 6px;
    border-radius: 6px;
    font-weight: 700;
    opacity: .9;

    span {
      font-size: 11px;
      color: white;
      margin: 0;
    }

    &.tag__duration, &.tag__date {
      background-color: $tag-default;
    }

    &.tag__cost_free {
      background-color: $tag-free;
    }

    &.tag__cost_paid {
      background-color: $tag-paid;
    }

    &.tag__type {
      background-color: $tag-type;
    }
  }

  .more-info-button {
    height: 33px;
    font-size: 14px;
    font-weight: bold;
    color: $font-color-light;
    background-color: $green-dark;
    border-radius: 9px;
    border: none;

    &:hover {
      transition: background-color .1ms ease-out, color .1ms ease-out;
      background-color: $hover-background-color;
      color: $hover-text-color;
    }
  }
}

.end-title {
  font-family: $title-font-family, sans-serif;
  font-size: calc(1rem + 3vw);
  color: $green-dark;
  margin-bottom: 27px;
}

.description.end-description {
    font-size: max(16px, calc((1rem + 1vw) / 2));
}


.footer-navigation {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 21px;
  justify-content: space-evenly;
  column-gap: 16px;
  flex: 1 0 16px;
  text-wrap: nowrap;
  color: $font-color-dark;

  a {
    color: unset;
    text-decoration: none;
  }
}

.hikes-footer {
  display: flex;
  justify-content: space-between;
  font-family: $main-font-family, sans-serif;
  color: $font-color-dark;
  padding: 30px 40px;

  .footer-left {
    display: flex;
    flex-direction: column;

    .rights {
      font-size: 17px;
    }

    .icon-usage {
      font-size: 13px;
      opacity: .5;

      position: relative;
      left: 20px;
    }
  }
}

@media screen and (max-width: $l-screen-size) {
  .back-button {
    position: relative;
  }
  .results-section {
    padding: 0 4vw;
  }

  .logotype-header {
    width: 200px;
    align-self: end;
  }

  .logotype-footer {
    width: 200px;
  }

  .footer-navigation {
    display: none;
  }

  .saved-button {
    width: 48px;
    height: 48px;

    span {
      display: none;
    }
  }

  .results-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: $m-screen-size) {
  .hikes-header {
    padding: 12px 4vw;
  }

  .results-container {
    grid-template-columns: repeat(2, 1fr);
  }

  .search-section {
      row-gap: 5px;
  }

  .back-button {
    position: relative;
    font-size: 18px;
  }

}

@media screen and (max-width: $s-screen-size) {
  .logotype-header {
    width: 175px;
    align-self: end;
  }

  .results-title {
    padding: 0;
  }

  .logotype-footer {
    width: 150px;
  }

  .hikes-footer {
    padding: 5vw;

    .footer-left {
      .rights {
        font-size: small;
      }

      .icon-usage {
        font-size: x-small;
        left: 16px;
      }
    }
  }
}

@media screen and (max-width: $xs-screen-size) {
  .hikes-footer {
    .footer-left {
      .rights {
        font-size: x-small;
      }

      .icon-usage {
        font-size: xx-small;
        left: 12px;
      }
    }
  }
}
