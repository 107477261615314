@use '../../globals/variables' as *;

.value {
  font-family: Onest, sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 21.67px;
  text-align: left;
  background-color: $background-color;
  color: transparentize(black, .6);
  height: 38px;
  display: flex;
  align-items: center;
  gap: 10px;
}
