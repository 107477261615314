@use '../../globals/variables' as *;

.hike-card {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 13px;
  background-color: $logotype-color;
  border: 1px solid $border-color;
  border-radius: 16px;
  overflow: hidden;
  padding: 8px;

  .card-img-wrapper {
    flex: 0 1 auto;
    border-radius: 11px;
    overflow: hidden;
    height: 101px;
    box-sizing: content-box;
  }

  .card-img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }

  .card-content {
    display: flex;
    flex-direction: column;
    gap: 13px;
    flex: 1 0 auto;
    padding: 0 4px;
  }

  .card-title {
    font-family: $title-font-family, sans-serif;
    color: $green-dark;
    font-size: max(1.5vw, 16px);
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .tag-container {
    display: flex;
    flex-wrap: wrap;
    gap: 3px 5px;
  }

  .tag {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    height: fit-content;
    padding: 2px 6px;
    border-radius: 6px;
    font-weight: 700;
    opacity: .9;

    span {
      font-size: 11px;
      color: white;
      margin: 0;
    }

    &.tag__duration, &.tag__date {
      background-color: $tag-default;
    }

    &.tag__cost_free {
      background-color: $tag-free;
    }

    &.tag__cost_paid {
      background-color: $tag-free;
    }

    &.tag__cost_paid {
      background-color: $tag-paid;
    }

    &.tag__type {
      background-color: $tag-type;
    }
  }

  .more-info-button {
    min-height: 33px;
    font-size: 14px;
    font-weight: bold;
    color: $font-color-light;
    background-color: $green-dark;
    border-radius: 9px;
    border: none;

    &:hover {
      transition: background-color .1ms ease-out, color .1ms ease-out;
      background-color: $hover-background-color;
      color: $hover-text-color;
    }
  }
}
